import React from "react";
import { useState } from "react";
import { useAutenticado } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import "../../Module.css";
import muni from "../../Assets/muni.png";
import { Footer } from "../Footer/Footer";
import { Container, Form, Button } from "react-bootstrap";
import { ModalInfo } from "./ModalInfo";

export function Login() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { login, loginGoogle, resetPassword } = useAutenticado();

  const handleChange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const navigate = useNavigate();
  const [error, setError] = useState();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError("");

    if (!user.email) return setError("Por favor ingresa un correo");
    try {
      await resetPassword(user.email);
      setError("Por favor revisa tu correo, se envío un enlace");
    } catch (error) {
      setError("Correo no registrado");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await login(user.email, user.password);
      navigate("/home");
    } catch (error) {
      setError("Usuario o contraseña incorrectos");
    }
  };

  const handleGoogle = async () => {
    try {
      await loginGoogle();
      navigate("/home");
    } catch (error) {
      setError("Algo no esta funcionando como esperábamos.");
    }
  };

  return (
    <Container>
      <div className="containergeneral">
        <div className="">
          <img className="logo" src={muni} alt="muni-logo" />
          <div className="title">EasySos - Haciendo tu vida más Segura</div>

          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <br />
              <Form.Control
                className="mb-2"
                type="email"
                name="email"
                placeholder="Tu correo"
                required
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group>
              <br />
              <Form.Control
                className="mb-3"
                type="password"
                name="password"
                id="password"
                placeholder="Tu contraseña"
                minLength={6}
                required
                autocomplete="off"
                onChange={handleChange}
              />
            </Form.Group>

            <Button className="mb-3" variant="primary" type="submit">
              Iniciar sesión
            </Button>
            <div>
              <a href="#!" onClick={handleResetPassword}>
                Recuperar Contraseña
              </a>
            </div>
          </Form>

          <hr></hr>
          <Button variant="flat" onClick={handleGoogle}>
            Acceder con Google
          </Button>

          <hr></hr>
          <a target="_blank" rel="noreferrer" href="/register">
            {" "}
            Regístrate
          </a>

          <style type="text/css">
            {`
              .btn-flat {
                background-color: purple;
                color: white;
                
              }
              .form.Control { 
                padding:0 10px;
              }
            `}
          </style>
        </div>

        {error && <ModalInfo message={error} />}

        <Footer />
      </div>
    </Container>
  );
}
