import face from "../../Assets/face.png";
import React from "react";

export const Footer = () => {
  return (
    <div className="footer">
      <h6>Conoce más sobre Easy Sos</h6>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/easysosapp"
      >
        <img className="footerimg" src={face} alt="Easy Sos Facebook" />{" "}
      </a>
    </div>
  );
};

export default Footer;
