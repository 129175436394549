import React from "react";
import "../Module.css";
import error from "../Assets/404.png";

export const PageNotFound = () => {
  return (
    <div className="containergeneral">
      <img className="img404" src={error} alt="404 central" />
    </div>
  );
};
export default PageNotFound;
