import { useAutenticado } from "../context/authContext";
import { Navigate } from "react-router-dom";

export function ProtectedRoute({ children }) {
  const { user, loading } = useAutenticado();

  if (loading)
    return (
      <div className="loadcontainer">
        <div className="load"></div>
      </div>
    );

  if (!user) return <Navigate to="/" />;

  return <>{children}</>;
}
