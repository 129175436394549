import React from "react";
import { useState } from "react";
import { useAutenticado } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import muni from "../../Assets/muni.png";
import { ModalInfo } from "./ModalInfo";
import "../../Module.css";
import { Footer } from "../Footer/Footer";
import { Form, Button } from "react-bootstrap";

export function Register() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { signup } = useAutenticado();
  const handleChange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signup(user.email, user.password);
      navigate("/");
    } catch (error) {
      setError("Upss! Sucedió un error");
    }
  };

  return (
    <div className="containergeneral">
      <div className="">
        <img className="logo" src={muni} alt="muni-logo" />
        <div className="title">EasySos - Haciendo tu vida más Segura</div>

        {error && <ModalInfo message={error} />}

        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <br />
            <Form.Control
              type="email"
              name="email"
              required
              placeholder="Correo"
              className="mb-2"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <br />
            <Form.Control
              type="password"
              name="password"
              placeholder="Tu contraseña"
              id="password"
              minLength={6}
              className="mb-3"
              required
              autocomplete="off"
              onChange={handleChange}
            />
          </Form.Group>

          <Button className="mb-3" variant="primary" type="submit">
            Registrar Usuario
          </Button>
        </Form>
        <hr></hr>
      </div>
      <Footer />
    </div>
  );
}
