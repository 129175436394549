import React, { useEffect, useState } from "react";
import { Stack, Button, Form, Table } from "react-bootstrap";
import { NavbarMenu } from "./NavbarMenu";
import { db } from "../firebase";
import {
  FaPen,
  FaGlobe,
  FaBan,
  FaImage,
  FaPaperclip,
  FaTrash,
} from "react-icons/fa";
import { Tooltip } from "./ToolTip/Tooltip";
import face from "../Assets/face.png";
import { BiVideo } from "react-icons/bi";
import { MdOutlineAudiotrack, MdDeleteForever } from "react-icons/md";
import { ModalDelete } from "./Modal/Delete/ModalDelete";
import { ModalAdd } from "./Modal/Add/ModalAdd";
import { ModalEdit } from "./Modal/Edit/ModalEdit";
import { ModalEditLocation } from "./Modal/Edit/EditLocation/ModalEditLocation";
import { ModalDeleteFiles } from "./Modal/Delete/deleteFiles/ModalDeleteFiles";
import { ModalDeleteVideo } from "./Modal/Delete/deleteVideo/ModalDeleteVideo";
import { ModalDeleteAudio } from "./Modal/Delete/deleteAudio/ModalDeleteAudio";

function searchingTerm(term) {
  return function (x) {
    return (
      x.delito.toLowerCase().includes(term) ||
      x.direccion.toLowerCase().includes(term) ||
      x.descripcion.toLowerCase().includes(term) ||
      !term
    );
  };
}

export const Home = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchs, setSearchs] = useState([]);
  const [term, setTerm] = useState([]);
  const [editar, setEditar] = useState([]);
  const [eliminar, setEliminar] = useState([]);
  const [editarLocation, setEditarLocation] = useState([]);
  const [isModalEditar, setIsModalEditar] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalDeleteLocation, setIsModalDeleteLocation] = useState(false);
  const [isModalDeleteFiles, setIsModalDeleteFiles] = useState(false);
  const [isModalDeleteVideo, setIsModalDeleteVideo] = useState(false);
  const [dataFilesDelete, setDataFilesDelete] = useState([]);
  const [dataVideoDelete, setDataVideoDelete] = useState([]);
  const [dataAudioDelete, setDataAudioDelete] = useState([]);
  const [isModalDeleteAudio, setIsModalDeleteAudio] = useState(false);

  const add = () => {
    setOpen(true);
  };

  useEffect(() => {
    setSearchs(data);
  }, [data]);

  const getData = () => {
    db.collection("tangamandapio")
      .orderBy("date", "desc")
      .onSnapshot((querySnapshot) => {
        const array = [];
        querySnapshot.forEach((doc) => {
          array.push({ ...doc.data(), id: doc.id });
        });
        setData(array);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <NavbarMenu />

      <ModalEditLocation
        setIsModalDeleteLocation={setIsModalDeleteLocation}
        isModalDeleteLocation={isModalDeleteLocation}
        editarLocation={editarLocation}
      />

      <ModalDeleteAudio
        isModalDeleteAudio={isModalDeleteAudio}
        setIsModalDeleteAudio={setIsModalDeleteAudio}
        dataAudioDelete={dataAudioDelete}
      />

      <ModalAdd setOpen={setOpen} open={open} />
      <ModalDeleteFiles
        isModalDeleteFiles={isModalDeleteFiles}
        setIsModalDeleteFiles={setIsModalDeleteFiles}
        dataFilesDelete={dataFilesDelete}
      />

      <ModalDeleteVideo
        isModalDeleteVideo={isModalDeleteVideo}
        setIsModalDeleteVideo={setIsModalDeleteVideo}
        dataVideoDelete={dataVideoDelete}
      />

      <ModalDelete
        isModalDelete={isModalDelete}
        setIsModalDelete={setIsModalDelete}
        eliminar={eliminar}
      />

      {editar && (
        <ModalEdit
          isModalEditar={isModalEditar}
          setIsModalEditar={setIsModalEditar}
          editar={editar}
        />
      )}

      <Form>
        <Stack direction="horizontal">
          <Form.Group controlId="busqueda" className="w-75 m-4">
            <Form.Control
              type="text"
              name="term"
              onChange={(e) => setTerm(e.target.value)}
              placeholder="Buscar por Delito, Descripción, Dirección"
            />
          </Form.Group>
          <hr />
        </Stack>
      </Form>

      <Button className="m-4" onClick={add}>
        Nueva Emergencia
      </Button>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/easysosapp"
      >
        <img className="footerimg" src={face} alt="Easy Sos Facebook" />{" "}
        Facebook
      </a>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Fecha</th>
            <th>Tipo</th>
            <th>Dirección</th>
            <th>Descripción</th>
            <th>Imágenes</th>
            <th>Vídeo</th>
            <th>Audio</th>
            <th>IA</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          {data.filter(searchingTerm(term)).map((datas, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{datefecha(datas.date).slice(1, -1)}</td>
              <td>{datas.delito}</td>
              <td>{datas.direccion}</td>
              <td>{datas.descripcion}</td>
              <td>{links(datas.images)}</td>
              <td>{link(datas.videos)}</td>
              <td>{linke(datas.audio)}</td>
              <td>{datas.ia}</td>

              <td>
                <Tooltip content="Editar" direction="left">
                  <div className="dispel">
                    <Button
                      variant="dark"
                      className="dispelButton"
                      onClick={() => {
                        setIsModalEditar(true);
                        setEditar({ ...datas });
                      }}
                    >
                      <FaPen />
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip content="Eliminar" direction="top">
                  <div className="dispel">
                    <Button
                      onClick={() => {
                        setIsModalDelete(true);
                        setEliminar(datas);
                      }}
                      className="dispelButton"
                      variant="danger"
                    >
                      <FaTrash />
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip content="Eliminar imágenes adjuntos" direction="top">
                  <div className="dispel">
                    <Button
                      onClick={() => {
                        setIsModalDeleteFiles(true);
                        setDataFilesDelete(datas);
                      }}
                      className="dispelButton"
                      variant="warning"
                    >
                      <FaPaperclip color="white" />
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip content="Eliminar Audio" direction="top">
                  <div className="dispel">
                    <Button
                      onClick={() => {
                        setIsModalDeleteAudio(true);
                        setDataAudioDelete(datas);
                      }}
                      className="dispelButton"
                      variant="success"
                    >
                      <MdDeleteForever color="white" />
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip content="Editar dirección" direction="bottom">
                  <div className="dispel">
                    <Button
                      onClick={() => {
                        setIsModalDeleteLocation(true);
                        setEditarLocation(datas);
                      }}
                      className="dispelButton"
                      variant="flat"
                    >
                      <style type="text/css">
                        {`
              .btn-flat {
                background-color: purple;
                color: white;
                
              }
              .form.Control { 
                padding:0 10px;
              }
            `}
                      </style>

                      <FaGlobe color="white" />
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip content="Eliminar video" direction="bottom">
                  <div className="dispel">
                    <Button
                      onClick={() => {
                        setIsModalDeleteVideo(true);
                        setDataVideoDelete(datas);
                      }}
                      className="dispelButton"
                      variant="primary"
                    >
                      <FaBan color="white" />
                    </Button>
                  </div>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

function datefecha(seconds) {
  let fechador;
  var months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  var date = new Date(seconds.seconds * 1000);
  var hours = date.getHours();
  var minutes = "0" + date.getMinutes();
  var second = "0" + date.getSeconds();
  var year = date.getFullYear();
  var month = months[date.getMonth()];
  var nameday = days[date.getDay()];
  var day = date.getDate();
  var formattedTime =
    nameday +
    " " +
    day +
    " " +
    month +
    " " +
    year +
    " " +
    hours +
    ":" +
    minutes.slice(-2) +
    ":" +
    second.slice(-2);

  let formatdate = JSON.stringify(formattedTime);
  fechador = formatdate;
  return fechador;
}

function links(images) {
  if (images.length !== 0) {
    var imgFirst = images[0];
    var imgSecond = images[1];

    if (images.length !== 1) {
      return (
        <>
          <a target="_blank" rel="noreferrer" href={imgFirst}>
            <FaImage />
          </a>
          &nbsp;&nbsp;
          <a target="_blank" rel="noreferrer" href={imgSecond}>
            <FaImage />
          </a>
        </>
      );
    } else {
      return (
        <>
          <a target="_blank" rel="noreferrer" href={imgFirst}>
            <FaImage />
          </a>
        </>
      );
    }
  }
}

function link(videos) {
  if (videos.length !== 0) {
    var video = videos[0];
    return (
      <>
        <a target="_blank" rel="noreferrer" href={video}>
          <BiVideo />
        </a>
      </>
    );
  }
}

function linke(audio) {
  if (audio.length !== 0) {
    var mp3 = audio[0];
    return (
      <>
        <a target="_blank" rel="noreferrer" href={mp3}>
          <MdOutlineAudiotrack />
        </a>
      </>
    );
  }
}
