import React from "react";
import politicas from "../Assets/politicas.png";
import { Container } from "react-bootstrap";
import "../Module.css";

export function Term() {
  return (
    <Container>
      <div className="containergeneral">
        <img className="imageTerm" src={politicas} alt="easysos-term" />
      </div>

      <div>
        <div className="tituloTerm">Términos & Políticas de Privacidad</div>
      </div>

      <br />

      <div className="parrafoTerm">
        Última Actualización: 17 de agosto de 2022
        <br />
        <br />
        Usamos sus datos personales para proporcionar y mejorar el Servicio. Al
        usar la aplicación <b>EasySos</b>, acepta la recopilación y el uso de
        información de acuerdo con esta Política de privacidad.
        <br />
        <br />
        <b>EasySos</b> es una aplicación que permite enviar reportes de
        incidentes en la vía pública, a través de videos o fotos que son
        grabados por los propios dispositivos de los ciudadanos, siendo de
        interés para la sociedad y publico en general.
        <br />
        <br />
        La aplicación de <b>EasySos</b> recopila datos de la ubicación precisa
        (geolocalización) en segundo plano al utilizar la función de{" "}
        <b>"DETALLES DE TU DENUNCIA"</b>, estos datos son utilizados como
        referencia de la ubicación del incidente del reporte enviado a las
        autoridades correspondientes para la toma de decisiones. La aplicación
        de <b>EasySos</b> no recopila ni envía información de la ubicación de
        nuestros usuarios cuando la aplicación está cerrada, se está utilizando
        otras funcionalidades de la aplicación o no está en uso.
        <br />
        <br />
        Si el usuario de la aplicación de <b>EasySos</b> deniega el permiso a la
        ubicación (geolocalización) limitará la experiencia de la aplicación y
        la función de <b>"DETALLES DE TU DENUNCIA"</b>, no logrando enviar los
        reportes de los incidentes a las autoridades correspondientes para la
        toma de decisiones.
        <br />
        <br />
        El contenido multimedia como fotos o videos enviados a través de la
        aplicación de <b>EasySos</b> se mantendrá hasta no exceder la
        infraestructura tecnológica de la aplicación o se mantendrá hasta que
        las autoridades correspondientes lo decidan, además el contenido
        multimedia no se utilizará para su venta o el uso de terceros. La
        aplicación de <b>EasySos</b> no revela información de nuestros usuarios
        de forma distinta a la expresada en esta política de privacidad.
        <br />
        <br />
        <b>Privacidad de los niños</b>
        <br />
        <br />
        Nuestro Servicio no se dirige a ninguna persona menor de 13 años. No
        recopilamos a sabiendas información de identificación personal de
        ninguna persona menor de 13 años. Si usted es padre o tutor y sabe que
        su hijo nos ha proporcionado Datos personales, por favor Contáctenos. Si
        nos damos cuenta de que hemos recopilado Datos personales de cualquier
        persona menor de 13 años sin verificación del consentimiento de los
        padres, tomamos medidas para eliminar esa información de Nuestros
        servidores.
        <br />
        <br />
        <b>Contáctenos</b>
        <br />
        Si tiene alguna pregunta sobre esta Política de privacidad, puede
        contactarnos: Por correo electrónico: <b>easysosseguridad@gmail.com</b>.
      </div>

      <br />
      <br />
      <br />
      <br />
    </Container>
  );
}
