import { Routes, Route } from "react-router-dom";
import { Login } from "./components/Account/Login";
import { Register } from "./components/Account/Register";
import { AuthProvider } from "./context/authContext";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { PageNotFound } from "./components/PageNotFound";
import { Home } from "./components/Home";
import { Map } from "./components/Map/Map";
import { Term } from "./components/Term";
import { Index } from "./components/Page/Index";

function App() {
  return (
    <div>
      <AuthProvider>
        <Routes>
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          <Route
            path="/map"
            element={
              <ProtectedRoute>
                <Map />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<PageNotFound />} />
          <Route path="terminos&privacidad" element={<Term />} />
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          {/*  <Route path="/" element={<Index />} /> */}
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
