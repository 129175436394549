import React from "react";
import { useAutenticado } from "../context/authContext";
import { Container, Navbar, Nav } from "react-bootstrap";

export const NavbarMenu = () => {
  const { logout } = useAutenticado();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {}
  };

  return (
    <div>
      <Navbar bg="primary" variant="dark">
        <Container>
          <Navbar.Brand href="#home">Central de Emergencias</Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link href="/home">Emergencias</Nav.Link>
              <Nav.Link href="/map">Mapa</Nav.Link>
              <Nav.Link href="#" onClick={handleLogout}>
                Salir
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
